@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/editor.module' as editorModule;
@use '@op/ui/src/consts/theme/colors.module' as colors;

.widget {
  $style: (
    margin-bottom: editorModule.$baseMarginBottom,
    padding-top: editorModule.$basePaddingVertical,
    padding-bottom: editorModule.$basePaddingVertical,
  );
  @include pxToVw.media-px-vw($style);
  @include media.media(mobile) {
    margin-bottom: editorModule.$baseMarginBottomMobile;
    padding-top: editorModule.$basePaddingVerticalMobile;
    padding-bottom: editorModule.$basePaddingVerticalMobile;
  }
}

.widgetBody {
  position: relative;
  display: block;
  background: colors.$toxicC_0;
  $style: (
    padding: pxToVw.calc-spacing(9) pxToVw.calc-spacing(6),
    border-radius: 20px,
  );
  @include pxToVw.media-px-vw($style);
}
