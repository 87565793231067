@use '../../consts/theme/wrap.module' as wrap;
@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;

.container {
  &.wrapper {
    --wrap-max-width: #{wrap.$maxWidth};
    --wrap-padding: 0 #{pxToVw.px-vw-small(wrap.$padding)};
    @include media.media(desktop-small) {
      --wrap-padding: 0 #{wrap.$padding};
    }
    @include media.media(desktop-medium) {
      --wrap-max-width: #{wrap.$maxWidthLarge};
      --wrap-padding: 0 #{wrap.$paddingLarge};
    }
    @include media.media(desktop-large) {
      --wrap-max-width: #{pxToVw.px-vw-large(wrap.$maxWidthLarge)};
      --wrap-padding: 0 #{wrap.$paddingLarge};
    }
    @include media.media(mobile) {
      --wrap-padding: 0 #{wrap.$paddingMobile};
    }
  }
}

.container {
  &.wrapper {
    max-width: var(--wrap-max-width);
    padding: var(--wrap-padding);
  }
}
