$baseMarginBottom: 24px;
$baseMarginBottomMobile: 16px;
$basePaddingVertical: 48px;
$basePaddingVerticalMobile: 24px;

:export {
  baseMarginBottom: $baseMarginBottom;
  baseMarginBottomMobile: $baseMarginBottomMobile;
  basePaddingVertical: $basePaddingVertical;
  basePaddingVerticalMobile: $basePaddingVerticalMobile;
}
