@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/editor.module' as editorModule;
@import '../widgets.module';

.incut {
  b.incutTitle {
    margin-bottom: pxToVw.calc-spacing(6);
    display: block;
    line-height: 1.2;
    $style: (
      font-size: 40px,
    );
    @include pxToVw.media-px-vw($style);
    @include media.media(mobile) {
      margin-bottom: pxToVw.calc-spacing(4);
      font-size: 24px;
      line-height: 1.17;
    }
  }
  span.incutText {
    display: block;
    margin-bottom: 0;
    line-height: 1.3;
    $style: (
      font-size: 22px,
      letter-spacing: -0.24px,
    );
    @include pxToVw.media-px-vw($style);
    @include media.media(mobile) {
      font-size: 16px;
      line-height: 1.2;
    }
  }
}
