//palette: https://www.figma.com/file/WOvoRlclQ7d5FxpW6UX9Dn/%E2%9C%85-Final?type=design&node-id=3834-4391&mode=design&t=xusBhKELRcVLdcLf-0
//postfix C_ - color
//postfix T_ - transparency

$base_blue: #0047ff;
$base_red: #ff2e0f;
$base_orange: #ff6a0f;
$base_bgBlack: #111112;
$base_darkGray: #2f3132;
$base_lightGray: #f3f6f7;
$base_gray1: #2d2d2d;
$base_gray1LightTheme: #f3f6f7;

$blueC_0: #24aded;
$blueC_50: #e0f4fd;
$blueC_100: #b1e2f9;
$blueC_200: #7ecef5;
$blueC_300: #4bbbef;
$blueC_500: #009fe9;
$blueC_600: #0092db;
$blueC_700: #007fc7;
$blueC_800: #006eb3;
$blueC_900: #004f91;

$redC_0: #ff2f0f;
$redC_50: #fde7e6;
$redC_100: #ffc8b8;
$redC_200: #ffa38b;
$redC_300: #ff7d5d;
$redC_500: #ff3613;
$redC_600: #ff2f0f;
$redC_700: #f12608;
$redC_800: #e31b02;
$redC_900: #cb0000;

$orangeC_0: #ffc107;
$orangeC_50: #fff8e1;
$orangeC_100: #ffedb3;
$orangeC_200: #ffe183;
$orangeC_300: #ffd650;
$orangeC_500: #ffc309;
$orangeC_600: #ffb401;
$orangeC_700: #ffa200;
$orangeC_800: #ff9100;
$orangeC_900: #ff7100;

$yellowC_0: #ffeb3b;
$yellowC_50: #fffde7;
$yellowC_100: #fff9c4;
$yellowC_200: #fff59d;
$yellowC_300: #fef075;
$yellowC_500: #ffeb3b;
$yellowC_600: #fdd835;
$yellowC_700: #fbc02d;
$yellowC_800: #f9a825;
$yellowC_900: #f57f16;

$toxicC_0: #d5fc44;
$toxicC_50: #f9ffe7;
$toxicC_100: #f1fec2;
$toxicC_200: #e5fd97;
$toxicC_300: #dafb69;
$toxicC_500: #cbf900;
$toxicC_600: #f1fec2;
$toxicC_700: #b1d000;
$toxicC_800: #a3b800;
$toxicC_900: #8c8f00;

$avocadoC_0: #8e9568;
$avocadoC_50: #f5f6ee;
$avocadoC_100: #e7e9d3;
$avocadoC_200: #d8dbb9;
$avocadoC_300: #c8cda0;
$avocadoC_400: #bdc28d;
$avocadoC_500: #b2b87c;
$avocadoC_600: #a2a973;
$avocadoC_800: #7b8160;
$avocadoC_900: #5c5f52;

$greenC_0: #3dcc4b;
$greenC_50: #e6f8e6;
$greenC_100: #c3edc2;
$greenC_200: #9ae19a;
$greenC_300: #6ad66f;
$greenC_500: #00c21f;
$greenC_600: #00b213;
$greenC_700: #009f00;
$greenC_800: #008e00;
$greenC_900: #006e00;

$grayC_0: #595959;
$grayC_50: #f9f9f9;
$grayC_100: #f2f2f2;
$grayC_200: #e9e9e9;
$grayC_300: #d9d9d9;
$grayC_400: #b5b5b5;
$grayC_500: #808080;
$grayC_600: #707070;
$grayC_800: #373737;
$grayC_900: #2b2b2b;
$grayC_1000: #1f1f1f;
$grayC_1100: #0a0a0a;

$gray_steelC_0: #f3f6f7;
$gray_steelC_50: #f8fbfc;
$gray_steelC_200: #eceff0;
$gray_steelC_300: #dfe2e3;
$gray_steelC_400: #bcbfc0;
$gray_steelC_500: #9da0a1;
$gray_steelC_600: #747778;
$gray_steelC_700: #494949;
$gray_steelC_800: #2c2c2c;
$gray_steelC_900: #0a0a0a;

$whiteT_08: rgba(255, 255, 255, 0.08);
$whiteT_12: rgba(255, 255, 255, 0.12);
$whiteT_16: rgba(255, 255, 255, 0.16);
$whiteT_24: rgba(255, 255, 255, 0.24);
$whiteT_32: rgba(255, 255, 255, 0.32);
$whiteT_40: rgba(255, 255, 255, 0.4);
$whiteT_50: rgba(255, 255, 255, 0.5);
$whiteT_64: rgba(255, 255, 255, 0.64);
$whiteT_72: rgba(255, 255, 255, 0.72);
$whiteT_80: rgba(255, 255, 255, 0.8);
$whiteT_88: rgba(255, 255, 255, 0.88);
$whiteT_100: #ffffff;

$blackT_08: rgba(0, 0, 0, 0.08);
$blackT_12: rgba(0, 0, 0, 0.12);
$blackT_16: rgba(0, 0, 0, 0.16);
$blackT_24: rgba(0, 0, 0, 0.24);
$blackT_32: rgba(0, 0, 0, 0.32);
$blackT_40: rgba(0, 0, 0, 0.4);
$blackT_50: rgba(0, 0, 0, 0.5);
$blackT_64: rgba(0, 0, 0, 0.64);
$blackT_72: rgba(0, 0, 0, 0.72);
$blackT_80: rgba(0, 0, 0, 0.8);
$blackT_88: rgba(0, 0, 0, 0.88);
$blackT_100: #000000;

:export {
  base_blue: $base_blue;
  base_red: $base_red;
  base_orange: $base_orange;
  base_bgBlack: $base_bgBlack;
  base_darkGray: $base_darkGray;
  base_lightGray: $base_lightGray;
  base_gray1: $base_gray1;
  base_gray1LightTheme: $base_gray1LightTheme;

  blueC_0: $blueC_0;
  blueC_50: $blueC_50;
  blueC_100: $blueC_100;
  blueC_200: $blueC_200;
  blueC_300: $blueC_300;
  blueC_500: $blueC_500;
  blueC_600: $blueC_600;
  blueC_700: $blueC_700;
  blueC_800: $blueC_800;
  blueC_900: $blueC_900;
  redC_0: $redC_0;
  redC_50: $redC_50;
  redC_100: $redC_100;
  redC_200: $redC_200;
  redC_300: $redC_300;
  redC_500: $redC_500;
  redC_600: $redC_600;
  redC_700: $redC_700;
  redC_800: $redC_800;
  redC_900: $redC_900;
  orangeC_0: $orangeC_0;
  orangeC_50: $orangeC_50;
  orangeC_100: $orangeC_100;
  orangeC_200: $orangeC_200;
  orangeC_300: $orangeC_300;
  orangeC_500: $orangeC_500;
  orangeC_600: $orangeC_600;
  orangeC_700: $orangeC_700;
  orangeC_800: $orangeC_800;
  orangeC_900: $orangeC_900;
  yellowC_0: $yellowC_0;
  yellowC_50: $yellowC_50;
  yellowC_100: $yellowC_100;
  yellowC_200: $yellowC_200;
  yellowC_300: $yellowC_300;
  yellowC_500: $yellowC_500;
  yellowC_600: $yellowC_600;
  yellowC_700: $yellowC_700;
  yellowC_800: $yellowC_800;
  yellowC_900: $yellowC_900;
  toxicC_0: $toxicC_0;
  toxicC_50: $toxicC_50;
  toxicC_100: $toxicC_100;
  toxicC_200: $toxicC_200;
  toxicC_300: $toxicC_300;
  toxicC_500: $toxicC_500;
  toxicC_600: $toxicC_600;
  toxicC_700: $toxicC_700;
  toxicC_800: $toxicC_800;
  toxicC_900: $toxicC_900;
  avocadoC_0: $avocadoC_0;
  avocadoC_50: $avocadoC_50;
  avocadoC_100: $avocadoC_100;
  avocadoC_200: $avocadoC_200;
  avocadoC_300: $avocadoC_300;
  avocadoC_400: $avocadoC_400;
  avocadoC_500: $avocadoC_500;
  avocadoC_600: $avocadoC_600;
  avocadoC_800: $avocadoC_800;
  avocadoC_900: $avocadoC_900;
  greenC_0: $greenC_0;
  greenC_50: $greenC_50;
  greenC_100: $greenC_100;
  greenC_200: $greenC_200;
  greenC_300: $greenC_300;
  greenC_500: $greenC_500;
  greenC_600: $greenC_600;
  greenC_700: $greenC_700;
  greenC_800: $greenC_800;
  greenC_900: $greenC_900;
  grayC_0: $grayC_0;
  grayC_50: $grayC_50;
  grayC_100: $grayC_100;
  grayC_200: $grayC_200;
  grayC_300: $grayC_300;
  grayC_400: $grayC_400;
  grayC_500: $grayC_500;
  grayC_600: $grayC_600;
  grayC_800: $grayC_800;
  grayC_900: $grayC_900;
  grayC_1000: $grayC_1000;
  grayC_1100: $grayC_1100;
  gray_steelC_0: $gray_steelC_0;
  gray_steelC_50: $gray_steelC_50;
  gray_steelC_200: $gray_steelC_200;
  gray_steelC_300: $gray_steelC_300;
  gray_steelC_400: $gray_steelC_400;
  gray_steelC_500: $gray_steelC_500;
  gray_steelC_600: $gray_steelC_600;
  gray_steelC_700: $gray_steelC_700;
  gray_steelC_800: $gray_steelC_800;
  gray_steelC_900: $gray_steelC_900;
  whiteT_08: $whiteT_08;
  whiteT_12: $whiteT_12;
  whiteT_16: $whiteT_16;
  whiteT_24: $whiteT_24;
  whiteT_32: $whiteT_32;
  whiteT_40: $whiteT_40;
  whiteT_50: $whiteT_50;
  whiteT_64: $whiteT_64;
  whiteT_72: $whiteT_72;
  whiteT_80: $whiteT_80;
  whiteT_88: $whiteT_88;
  whiteT_100: $whiteT_100;
  blackT_08: $blackT_08;
  blackT_12: $blackT_12;
  blackT_16: $blackT_16;
  blackT_24: $blackT_24;
  blackT_32: $blackT_32;
  blackT_40: $blackT_40;
  blackT_50: $blackT_50;
  blackT_64: $blackT_64;
  blackT_72: $blackT_72;
  blackT_80: $blackT_80;
  blackT_88: $blackT_88;
  blackT_100: $blackT_100;
}
