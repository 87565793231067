@use '../../styles/mixins/pxToVw.module' as pxToVw;

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.fill {
  position: fixed;
}

.icon {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  $style: (
    width: 60px,
    height: 60px,
  );
  @include pxToVw.media-px-vw($style);
}

#loaderIconG2To {
  animation: e0H8OsGJKRa2_to__to 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa2_to__to {
  0% {
    transform: translate(31.5px, 24.75px);
  }
  25% {
    transform: translate(31.5px, 24.75px);
    animation-timing-function: cubic-bezier(0.79, -0.17, 0.12, 1.22);
  }
  50% {
    transform: translate(25.5px, 21.25px);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  70.833333% {
    transform: translate(25.5px, 21.25px);
  }
  100% {
    transform: translate(25.5px, 21.25px);
  }
}
#loaderIconG2 {
  animation: e0H8OsGJKRa2_c_o 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa2_c_o {
  0% {
    opacity: 0;
  }
  8.333333% {
    opacity: 0;
  }
  16.666667% {
    opacity: 1;
  }
  83.333333% {
    opacity: 1;
  }
  91.666667% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#loaderIconG3To {
  animation: e0H8OsGJKRa3_to__to 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa3_to__to {
  0% {
    transform: translate(6.5px, 24.75px);
  }
  25% {
    transform: translate(6.5px, 24.75px);
    animation-timing-function: cubic-bezier(0.79, -0.17, 0.12, 1.22);
  }
  50% {
    transform: translate(12.5px, 21.25px);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  70.833333% {
    transform: translate(12.5px, 21.25px);
  }
  100% {
    transform: translate(12.5px, 21.25px);
  }
}
#loaderIconG3 {
  animation: e0H8OsGJKRa3_c_o 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa3_c_o {
  0% {
    opacity: 0;
  }
  8.333333% {
    opacity: 1;
  }
  83.333333% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#loaderIconG4To {
  animation: e0H8OsGJKRa4_to__to 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa4_to__to {
  0% {
    transform: translate(12.5px, 3.5px);
  }
  25% {
    transform: translate(12.5px, 3.5px);
    animation-timing-function: cubic-bezier(0.79, -0.17, 0.12, 1.22);
  }
  50% {
    transform: translate(12.5px, 7px);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  70.833333% {
    transform: translate(12.5px, 7px);
  }
  100% {
    transform: translate(12.5px, 7px);
  }
}
#loaderIconG4 {
  animation: e0H8OsGJKRa4_c_o 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa4_c_o {
  0% {
    opacity: 0;
  }
  16.666667% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  70.833333% {
    opacity: 1;
  }
  83.333333% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#loaderIconG5To {
  animation: e0H8OsGJKRa5_to__to 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa5_to__to {
  0% {
    transform: translate(25.5px, 3.5px);
  }
  25% {
    transform: translate(25.5px, 3.5px);
    animation-timing-function: cubic-bezier(0.79, -0.17, 0.12, 1.22);
  }
  50% {
    transform: translate(25.5px, 7px);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  70.833333% {
    transform: translate(25.5px, 7px);
  }
  100% {
    transform: translate(25.5px, 7px);
  }
}
#loaderIconG5 {
  animation: e0H8OsGJKRa5_c_o 2400ms linear infinite normal forwards;
}
@keyframes e0H8OsGJKRa5_c_o {
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 0;
  }
  20.833333% {
    opacity: 1;
  }
  70.833333% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
