@use 'sass:map';
@use '../../consts/theme/breakpoints.module' as breakpoints;
@use 'medias.module' as m;
@use '../../consts/theme/spacing.module' as spacing;

$spacingVal: spacing.$spacing;

@function constToPx($target) {
  $targetPx: calc($target * 1px);
  @return $targetPx;
}

@function calc-spacing($target) {
  $spacing: calc(constToPx($target) * $spacingVal);
  @return $spacing;
}

@function px-vw-small($target) {
  $vw-context: (breakpoints.$desktopSmall * 0.01) * 1px;
  @return (calc($target / $vw-context)) * 1vw;
}

@function px-vw-large($target) {
  $vw-context: (breakpoints.$desktopLarge * 0.01) * 1px;
  @return (calc($target / $vw-context)) * 1vw;
}

@function px-vw-desktops($target, $media) {
  $multiValues: ();
  $vw-context: ($media * 0.01) * 1px;

  @each $val in $target {
    @if $val == 0 {
      $multiValues: append($multiValues, 0, 'space');
    } @else if type-of($val) == color {
      $multiValues: append($multiValues, $val, 'space');
    } @else {
      $multiValues: append($multiValues, ((calc($val / $vw-context)) * 1vw), 'space');
    }
  }
  @return $multiValues;
}

@mixin media-px-vw($localVar) {
  $scalable-small: ();
  $static: ();
  $scalable-large: ();
  @each $name, $value in $localVar {
    $scalable-small-value: px-vw-desktops($value, breakpoints.$desktopSmall);
    $scalable-small: map.set($scalable-small, $name, $scalable-small-value);

    $static-value: $value;
    $static: map.set($static, $name, $static-value);

    $scalable-large-value: px-vw-desktops($value, breakpoints.$desktopLarge);
    $scalable-large: map.set($scalable-large, $name, $scalable-large-value);
  }

  @each $name, $value in $static {
    #{$name}: #{$value};
  }

  @media screen and (min-width: #{breakpoints.$tabletStart}px) {
    @each $name, $value in $scalable-small {
      #{$name}: #{$value};
    }
  }

  @media screen and (min-width: #{breakpoints.$desktopSmall}px) {
    @each $name, $value in $static {
      #{$name}: #{$value};
    }
  }

  @media screen and (min-width: #{breakpoints.$desktopLarge}px) {
    @each $name, $value in $scalable-large {
      #{$name}: #{$value};
    }
  }
}
