.vkButton {
  width: inherit;
  min-height: inherit;
  height: inherit;
  padding: 0;
  border: 0;
  svg {
    --OP-svgIcon-size-width: 100%;
    --OP-svgIcon-size-height: 100%;
  }
}
