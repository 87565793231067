@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/colors.module' as colors;

.author {
  display: flex;
  align-items: center;
  flex: 0 1 100%;
}

.avatar {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background-color: colors.$blueC_300;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  border-radius: 50%;
  $style: (
    width: 36px,
    height: 36px,
    margin-right: pxToVw.calc-spacing(4),
    font-size: 12px,
  );
  @include pxToVw.media-px-vw($style);
}

.authorName {
  flex: 0 1 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include media.media(mobile) {
    overflow: unset;
    white-space: normal;
    font-size: 12px;
    line-height: 1.35;
  }
}

.dataItemMobile {
  display: none;
  @include media.media(mobile) {
    display: flex;
    color: colors.$gray_steelC_600;
  }
}

.dateUpdate {
  border-left: 1px solid colors.$gray_steelC_600;
  $style: (
    padding-left: pxToVw.calc-spacing(4),
  );
  @include pxToVw.media-px-vw($style);
  @include media.media(mobile) {
    padding-left: 0;
    border-left: 0;
    font-size: 12px;
    line-height: 1.35;
  }
}

.dateCreate {
  line-height: 1.5;
  $style: (
    margin-right: pxToVw.calc-spacing(4),
  );
  @include pxToVw.media-px-vw($style);
  @include media.media(mobile) {
    margin-right: 0;
    font-size: 12px;
    line-height: 1.35;
  }
}

.signatureData {
  flex: 0 1 100%;
  line-height: 1;
  font-size: 0;
}
