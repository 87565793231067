@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/colors.module' as colors;

.share {
  position: relative;
  line-height: 1;
}

.button {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: none;
  $styles: (
    padding: pxToVw.calc-spacing(0.5) pxToVw.calc-spacing(4),
    height: 32px,
    border-radius: 8px,
  );
  @include pxToVw.media-px-vw($styles);
  &:hover {
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.12);
  }
  @include media.media(mobile) {
    --OP-S-buttons-iconMargin: 0;
    font-size: 0;
    :global {
      .MuiButton-iconSizeS {
        --OP-S-buttons-iconMargin: 0;
      }
    }
    svg {
      margin: 0;
    }
  }
}

.icon {
  $styles: (
    margin-right: pxToVw.calc-spacing(1),
  );
  @include pxToVw.media-px-vw($styles);
}

.dropdown {
  position: absolute;
  left: 0;
  background: colors.$whiteT_100;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 2;
  transform: translate(0, 100%);
  $styles: (
    width: 280px,
    padding: pxToVw.calc-spacing(2),
    bottom: pxToVw.calc-spacing(2.5) * -1,
    border-radius: 12px,
  );
  @include pxToVw.media-px-vw($styles);
  @include media.media(mobile) {
    left: auto;
    right: 0;
  }
}

.dropdownButton {
  position: relative;
  $styles: (
    padding: pxToVw.calc-spacing(2.5) pxToVw.calc-spacing(4) pxToVw.calc-spacing(2) pxToVw.calc-spacing(11),
    border-radius: 12px,
  );
  @include pxToVw.media-px-vw($styles);
  :global {
    .MuiTouchRipple-root {
      display: none;
    }
  }
  &:hover {
    background: rgba(197, 210, 216, 0.2);
  }
  &:active {
    transform: scale(0.96);
    transition: all 0.2s ease;
  }
}

.dropdownExternalButton {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  svg {
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    $style: (
      --OP-svgIcon-size-width: 16px,
      --OP-svgIcon-size-height: 16px,
      left: pxToVw.calc-spacing(2.75),
    );
    @include pxToVw.media-px-vw($style);
  }
}

.dropdownText {
  flex: 0 1 100%;
  line-height: 1.33;
}
