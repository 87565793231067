@use '../../styles/mixins/pxToVw.module' as pxToVw;
@use '../../styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/colors.module' as colors;

.hU {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  @include media.media(mobile) {
    --typographyH5LineHeight: 1.25;
    --typographyH5FontSize: 16px;
    justify-content: flex-start;
  }
}

.badge {
  background: colors.$base_orange;
  color: colors.$whiteT_100;
  $styles: (
    margin: 0 pxToVw.calc-spacing(4),
    border-radius: 100px,
    padding: pxToVw.calc-spacing(2.5) pxToVw.calc-spacing(4),
  );
  @include pxToVw.media-px-vw($styles);
}
