@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '../../consts/theme/colors.module' as colors;

.skeleton {
  position: relative;
  width: 100%;
  height: 100%;
  background: colors.$grayC_300;
  overflow: hidden;
  box-shadow: 0 50px 60px 0 colors.$blackT_16;
  $style: (
    border-radius: 20px,
  );
  @include pxToVw.media-px-vw($style);
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  &.dark:before {
    background: linear-gradient(110deg, colors.$grayC_1000 8%, colors.$grayC_900 18%, colors.$grayC_1000 33%);
    background-size: 200% 100%;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
