@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/fonts.module' as fonts;

.typography {
  font-family: fonts.$fontBase;
  color: var(--text-color);
}

.hero {
  font-size: var(--typographyHeroFontSize);
  font-weight: var(--typographyHeroFontWeight);
  line-height: var(--typographyHeroLineHeight);
  letter-spacing: var(--typographyHeroLetterSpacing);
}

.h1 {
  font-size: var(--typographyH1FontSize);
  font-weight: var(--typographyH1FontWeight);
  line-height: var(--typographyH1LineHeight);
  letter-spacing: var(--typographyH1LetterSpacing);
}

.h2 {
  font-size: var(--typographyH2FontSize);
  font-weight: var(--typographyH2FontWeight);
  line-height: var(--typographyH2LineHeight);
  letter-spacing: var(--typographyH2LetterSpacing);
}

.h3 {
  font-size: var(--typographyH3FontSize);
  font-weight: var(--typographyH3FontWeight);
  line-height: var(--typographyH3LineHeight);
  letter-spacing: var(--typographyH3LetterSpacing);
}

.h4 {
  font-size: var(--typographyH4FontSize);
  font-weight: var(--typographyH4FontWeight);
  line-height: var(--typographyH4LineHeight);
  letter-spacing: var(--typographyH4LetterSpacing);
}

.h5 {
  font-size: var(--typographyH5FontSize);
  font-weight: var(--typographyH5FontWeight);
  line-height: var(--typographyH5LineHeight);
  letter-spacing: var(--typographyH5LetterSpacing);
}

.h6 {
  font-size: var(--typographyH6FontSize);
  font-weight: var(--typographyH6FontWeight);
  line-height: var(--typographyH6LineHeight);
  letter-spacing: var(--typographyH6LetterSpacing);
}

.subtitle1 {
  font-size: var(--typographySubtitle1FontSize);
  font-weight: var(--typographySubtitle1FontWeight);
  line-height: var(--typographySubtitle1LineHeight);
  letter-spacing: var(--typographySubtitle1LetterSpacing);
}

.subtitle2 {
  font-size: var(--typographySubtitle2FontSize);
  font-weight: var(--typographySubtitle2FontWeight);
  line-height: var(--typographySubtitle2LineHeight);
  letter-spacing: var(--typographySubtitle2LetterSpacing);
}

.body1 {
  font-size: var(--typographyBody1FontSize);
  font-weight: var(--typographyBody1FontWeight);
  line-height: var(--typographyBody1LineHeight);
  letter-spacing: var(--typographyBody1LetterSpacing);
}

.body2 {
  font-size: var(--typographyBody2FontSize);
  font-weight: var(--typographyBody2FontWeight);
  line-height: var(--typographyBody2LineHeight);
  letter-spacing: var(--typographyBody2LetterSpacing);
}

.links {
  font-size: var(--typographyLinksFontSize);
  font-weight: var(--typographyLinksFontWeight);
  line-height: var(--typographyLinksLineHeight);
  letter-spacing: var(--typographyLinksLetterSpacing);
}

.small {
  font-size: var(--typographySmallFontSize);
  font-weight: var(--typographySmallFontWeight);
  line-height: var(--typographySmallLineHeight);
  letter-spacing: var(--typographySmallLetterSpacing);
}

.caption {
  font-size: var(--typographyCaptionFontSize);
  font-weight: var(--typographyCaptionFontWeight);
  line-height: var(--typographyCaptionLineHeight);
  letter-spacing: var(--typographyCaptionLetterSpacing);
}

.button {
  font-size: var(--typographyButtonFontSize);
  font-weight: var(--typographyButtonFontWeight);
  line-height: var(--typographyButtonLineHeight);
  letter-spacing: var(--typographyButtonLetterSpacing);
}

.buttonSmall {
  font-size: var(--typographyButtonSmallFontSize);
  font-weight: var(--typographyButtonSmallFontWeight);
  line-height: var(--typographyButtonSmallLineHeight);
  letter-spacing: var(--typographyButtonSmallLetterSpacing);
}

.inherit {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

strong.typography {
  font-weight: fonts.$fontWeightBold;
}
