@use '../../consts/theme/breakpoints.module' as breakpoints;

@mixin media($point) {
  @if $point==desktop-large {
    @media screen and (min-width: #{breakpoints.$desktopLarge}px) {
      @content;
    }
  } @else if $point==desktop-medium {
    @media screen and (min-width: #{breakpoints.$desktopMiddle}px) {
      @content;
    }
  } @else if $point==desktop-small {
    @media screen and (min-width: #{breakpoints.$desktopSmall}px) {
      @content;
    }
  } @else if $point==tablet {
    @media screen and (max-width: #{breakpoints.$tablet}px) {
      @content;
    }
  } @else if $point==mobile {
    @media screen and (max-width: #{breakpoints.$mobile}px) {
      @content;
    }
  } @else if $point==phone {
    @media screen and (max-width: 374px) {
      @content;
    }
  }
}
